import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AppSettings } from './app.settings';
import { Settings } from './app.settings.model';
import { Location, PopStateEvent } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  public settings: Settings;
    lastPoppedUrl: string;
    urlname: any;
  constructor(public appSettings: AppSettings, public translate: TranslateService, private router: Router, private location: Location, public http: HttpClient) {
        this.settings = this.appSettings.settings; 
        translate.addLangs(['en','de','fr','ru','tr']);
        translate.setDefaultLang('en'); 
        translate.use('en'); 
  }
  ngOnInit(): void{
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      debugger
      this.urlname = data.Webservice;
    })
    if (this.urlname =='https://mytaskmgmt.breakingindiaapp.com/webservices/') {
      window.location.href = location.href.replace('http', 'https');
    }
    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    })
  }


}
